var StorageKeys;
(function (StorageKeys) {
    StorageKeys["TOKEN"] = "TOKEN";
    StorageKeys["ORDER_ID"] = "ORDER_ID";
    StorageKeys["USER_UID"] = "USER_UID";
    StorageKeys["API_KEY"] = "API_KEY";
    StorageKeys["PAYMENT_CHANNEL"] = "PAYMENT_CHANNEL";
})(StorageKeys || (StorageKeys = {}));
class StorageItem {
    constructor(key) {
        this.key = key;
        try {
            const storedValue = (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem(this.key)) || undefined;
            this.value = storedValue;
        }
        catch (_a) {
            // ignored
        }
    }
    get() {
        return this.value;
    }
    set(value) {
        try {
            sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem(this.key, value);
        }
        catch (_a) {
            // ignored
        }
        finally {
            this.value = value;
        }
    }
    remove() {
        try {
            sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.removeItem(this.key);
        }
        catch (_a) {
            // ignored
        }
        finally {
            this.value = undefined;
        }
    }
}
const storagesFromKeys = Object.values(StorageKeys).reduce((previousValue, key) => {
    previousValue[key] = new StorageItem(key);
    return previousValue;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}, {});
const createStorage = () => {
    const clearAll = () => {
        for (const storageItem of Object.values(storagesFromKeys)) {
            storageItem.remove();
        }
    };
    return Object.assign(Object.assign({}, storagesFromKeys), { clearAll });
};
export const storage = createStorage();
