var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MessageTypes, } from 'common';
import { computed, makeObservable } from 'mobx';
import { getNonEditableDefaultValue } from '../../View/Contexts/SettingsContext';
import { sendMessageToHost } from '../../utils/sendMessageToHost';
import { ExchangeInfoView } from '../ViewModels/ExchangeInfoView';
import { BLOCKED_USER_ERROR_CODE } from '../apiIntegrations/const';
import { cancelPaymentWithWire, completePaymentWithApplePay, createWallet, getOrderPaymentInfo, initPaymentWithApplePay, initPaymentWithSkrill, initPaymentWithWire, persistBillDetails, sendEmail, sendTargetAddress, userDetails, } from '../methods/order';
import { saveAddress } from '../savedWalletAddress';
import { storage } from '../storage';
import { removeUserToken } from '../userToken';
import { getDefaultAmounts, } from './ExchangeController';
import { Page, } from './StepController';
import { recording } from '../../openReplay';
export class OrderFacade {
    constructor(stepController, userController, orderController, exchangeController, paymentController, currencies, defaultValues) {
        this.stepController = stepController;
        this.userController = userController;
        this.orderController = orderController;
        this.exchangeController = exchangeController;
        this.paymentController = paymentController;
        this.currencies = currencies;
        this.defaultValues = defaultValues;
        this.setPaymentCardType = (type) => {
            this.paymentController.setPayment(type);
        };
        this.checkErrorForBlockUser = (e) => {
            var _a, _b, _c;
            if (((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status) === BLOCKED_USER_ERROR_CODE) {
                this.stepController.updateStepInfo({
                    nextStep: {
                        step: Page.BLOCKED,
                    },
                });
            }
            throw ((_c = (_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || 'Something went wrong';
        };
        this.updateStepsInfo = () => __awaiter(this, void 0, void 0, function* () {
            yield this.orderController.getOrder();
            if (this.order && 'currentStep' in this.order) {
                this.stepController.updateStepInfo({
                    nextStep: this.order.currentStep,
                    stepsHistory: this.order.stepsHistory,
                });
            }
        });
        this.createOrderWithToken = (userToken, onError) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            const targetAddressFrontPrefill = getNonEditableDefaultValue((_a = this.defaultValues) === null || _a === void 0 ? void 0 : _a.targetAddress);
            const userPhoneFrontPrefill = (_c = (_b = this.defaultValues) === null || _b === void 0 ? void 0 : _b.phone) === null || _c === void 0 ? void 0 : _c.value;
            const userEmailFrontPrefill = (_e = (_d = this.defaultValues) === null || _d === void 0 ? void 0 : _d.email) === null || _e === void 0 ? void 0 : _e.value;
            return userDetails(userToken)
                .then((res) => {
                const isPhonesEqual = !userPhoneFrontPrefill ||
                    res.phone.replaceAll('+', '') ===
                        userPhoneFrontPrefill.replaceAll('+', '');
                const isEmailsEqual = !userEmailFrontPrefill || res.email === userEmailFrontPrefill;
                if (!isPhonesEqual || !isEmailsEqual) {
                    throw new Error('User data from token is not equal to default values');
                }
                this.userController.setField(res.phone, 'phone');
                this.userController.setField(res.email, 'email');
            })
                .then(() => {
                return this.createOrder({
                    userInfo: { userToken, targetAddress: targetAddressFrontPrefill },
                });
            })
                .catch(() => {
                removeUserToken();
                onError();
            });
        });
        this.createOrder = ({ userInfo, captcha, extendedCaptcha, }) => __awaiter(this, void 0, void 0, function* () {
            var _f;
            try {
                const req = this.exchangeController.exchangeDto;
                const res = yield this.orderController.createOrder(userInfo, req, captcha, extendedCaptcha);
                storage.TOKEN.set(res.token);
                storage.USER_UID.set(res.userUid);
                storage.ORDER_ID.set(res.orderUid);
                recording.setUserID(res.orderUid);
                this.exchangeController.stopWatching();
                yield this.orderController.startWatching();
                this.stepController.updateStepInfo(res);
                if (userInfo === null || userInfo === void 0 ? void 0 : userInfo.phone) {
                    this.userController.setField(userInfo === null || userInfo === void 0 ? void 0 : userInfo.phone, 'phone');
                }
                if (userInfo.email) {
                    this.userController.setField(userInfo.email, 'email');
                }
                if (userInfo.targetAddress) {
                    this.userController.setField(userInfo.targetAddress, 'address');
                }
            }
            catch (e) {
                if (((_f = e === null || e === void 0 ? void 0 : e.response) === null || _f === void 0 ? void 0 : _f.status) !== BLOCKED_USER_ERROR_CODE &&
                    this.orderController.order) {
                    this.orderController.removeOrder();
                    this.orderController.stopWatching();
                    this.exchangeController.startWatching();
                }
                this.checkErrorForBlockUser(e);
            }
        });
        this.getPaymentInfo = ({ method, isRetry = false, }) => __awaiter(this, void 0, void 0, function* () {
            try {
                let paymentInfoData;
                if (method !== 'SKRILL') {
                    const paymentInfoResponse = yield getOrderPaymentInfo(this.paymentController.paymentForService, method);
                    paymentInfoData = paymentInfoResponse.data;
                }
                this.stepController.updateStepInfo({
                    nextStep: Object.assign({ step: Page.PAYMENT, method,
                        isRetry }, paymentInfoData),
                    stepsHistory: [
                        ...this.stepController.stepsHistory,
                        { step: Page.CHECK_ORDER },
                    ],
                });
            }
            catch (err) {
                this.checkErrorForBlockUser(err);
                throw err;
            }
        });
        this.initiateWirePayment = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield initPaymentWithWire();
                yield this.refreshOrder();
                return res.data;
            }
            catch (err) {
                this.checkErrorForBlockUser(err);
                throw err;
            }
        });
        this.cancelWirePayment = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield cancelPaymentWithWire();
                this.stepController.updateStepInfo(res.data);
                yield this.refreshOrder();
            }
            catch (err) {
                this.checkErrorForBlockUser(err);
                throw err;
            }
        });
        this.initiateApplePayPayment = () => __awaiter(this, void 0, void 0, function* () {
            const applePayRequest = {
                countryCode: 'EE',
                currencyCode: this.exchangeInfo.amounts.from.currency.code,
                requiredBillingContactFields: ['postalAddress'],
                merchantCapabilities: ['supports3DS'],
                supportedNetworks: ['visa', 'masterCard'],
                total: {
                    label: `Buy ${this.exchangeInfo.amounts.to.currency.code}`,
                    amount: this.exchangeInfo.amounts.from.amount,
                    type: 'final',
                },
            };
            const session = new ApplePaySession(3, applePayRequest);
            session.onvalidatemerchant = () => __awaiter(this, void 0, void 0, function* () {
                try {
                    const sessionResp = yield initPaymentWithApplePay();
                    session.completeMerchantValidation(JSON.parse(JSON.stringify(sessionResp.data.session)));
                }
                catch (error) {
                    console.error("Can't complete Apple Pay merchant validation", error);
                    session.abort();
                }
            });
            session.onpaymentauthorized = (ev) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const response = yield completePaymentWithApplePay(ev.payment);
                    this.stepController.updateStepInfo(response.data);
                    session.completePayment({
                        status: ApplePaySession.STATUS_SUCCESS,
                    });
                }
                catch (error) {
                    console.error("Can't complete Apple Pay payment", error);
                    session.completePayment({
                        status: ApplePaySession.STATUS_FAILURE,
                    });
                }
            });
            session.begin();
        });
        this.initiateSkrillPayment = (billingAddress) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield initPaymentWithSkrill(billingAddress);
                this.stepController.updateStepInfo(response.data);
                // await this.refreshOrder();
            }
            catch (err) {
                this.checkErrorForBlockUser(err);
                throw err;
            }
        });
        this.persistBillDetails = (billingAddress) => persistBillDetails(billingAddress);
        this.sendEmail = (dto) => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield sendEmail(dto);
                if ('email' in dto) {
                    this.userController.setField(dto.email, 'email');
                }
                // todo: Нужно научить принимать разные типы
                this.userController.setField(`${dto.subscribe ? 'true' : ''}`, 'subscribe');
                this.stepController.updateStepInfo(res.data);
            }
            catch (e) {
                this.checkErrorForBlockUser(e);
            }
        });
        this.resendEmail = () => __awaiter(this, void 0, void 0, function* () {
            return this.sendEmail({
                email: this.userController.email,
                subscribe: !!this.userController.subscribe,
            });
        });
        this.sendAddress = (address) => __awaiter(this, void 0, void 0, function* () {
            var _g;
            try {
                const res = yield sendTargetAddress({ address });
                this.userController.setField(address, 'address');
                sendMessageToHost({
                    type: MessageTypes.TARGET_ADDRESS_ENTERED,
                    payload: {
                        address,
                    },
                });
                const currency = (_g = this.orderController.order) === null || _g === void 0 ? void 0 : _g.amounts.to.currency;
                saveAddress(address, currency);
                this.stepController.updateStepInfo(res.data);
            }
            catch (e) {
                this.checkErrorForBlockUser(e);
            }
        });
        this.createWallet = () => __awaiter(this, void 0, void 0, function* () {
            var _h;
            try {
                const res = yield createWallet();
                if (res.data.address) {
                    sendMessageToHost({
                        type: MessageTypes.TARGET_ADDRESS_ENTERED,
                        payload: {
                            address: res.data.address,
                        },
                    });
                    const currency = (_h = this.orderController.order) === null || _h === void 0 ? void 0 : _h.amounts.to.currency;
                    saveAddress(res.data.address, currency);
                }
                this.stepController.updateStepInfo(res.data);
                this.userController.setField(res.data.address, 'address');
            }
            catch (e) {
                this.checkErrorForBlockUser(e);
            }
        });
        if (this.orderController.order) {
            this.orderController.startWatching();
        }
        else {
            this.exchangeController.startWatching();
        }
        makeObservable(this, {
            order: computed,
            exchangeInfo: computed,
            userInfo: computed,
            paymentCardType: computed,
        });
    }
    get paymentCardType() {
        return this.paymentController.type;
    }
    get errors() {
        return {
            estimate: this.exchangeController.error,
        };
    }
    get userInfo() {
        return {
            address: this.userController.address,
            email: this.userController.email,
            phone: this.userController.phone,
            subscribe: this.userController.subscribe,
        };
    }
    get order() {
        return this.orderController.order;
    }
    get NFTInfo() {
        var _a, _b;
        return (((_b = (_a = this.orderController.order) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.smartContractData) ||
            this.exchangeController.smartContractData);
    }
    get exchangeInfo() {
        var _a;
        return new ExchangeInfoView(this.exchangeController.ttl, this.exchangeController.exchangeInfo ||
            {
                amounts: getDefaultAmounts(this.exchangeController.exchangeDto, this.currencies.currenciesMap),
            }, this.orderController.order, (_a = this.exchangeController.exchangeDto.metadata) === null || _a === void 0 ? void 0 : _a.smartContractData.name);
    }
    changeExchangeInfo(value) {
        this.orderController.stopWatching();
        this.orderController.removeOrder();
        return this.exchangeController.changeRequest(value);
    }
    setStepsInfo(info) {
        this.stepController.updateStepInfo(info);
    }
    setStep(step) {
        this.stepController.setStep(step);
    }
    refreshOrder() {
        return this.orderController.getOrder();
    }
}
