var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { httpClient } from '../apiIntegrations/httpClient';
export const estimateRates = (dto) => {
    return httpClient.post('/orders/estimateRates', dto);
};
export const createOrder = (dto) => {
    return httpClient.post('/orders', dto);
};
export const userDetails = (userToken) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield httpClient.post('/orders/userDetails', {
        userToken,
    });
    return res.data;
});
export const getCurrentOrder = () => {
    return httpClient.get('/orders/current');
};
export const getOrderByToken = (token) => {
    return axios.get(`${process.env.REACT_APP_API_HOST}/orders/current`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["IFRAME"] = "IFRAME";
    PaymentMethod["DIRECT"] = "DIRECT";
    PaymentMethod["APPLE"] = "APPLE";
    PaymentMethod["SKRILL"] = "SKRILL";
})(PaymentMethod || (PaymentMethod = {}));
export const getOrderPaymentInfo = (cardType, method = PaymentMethod.IFRAME) => {
    const params = new URLSearchParams({
        cardType,
        method,
    }).toString();
    return httpClient.get(`/orders/current/payment?${params}`);
};
export const sendEmail = (dto) => {
    const body = Object.assign(Object.assign({}, dto), { skipVerification: true });
    return httpClient.put('/orders/current/email', body);
};
export const sendTargetAddress = (dto) => {
    return httpClient.put('/orders/current/target', dto);
};
export const createWallet = () => {
    return httpClient.post('/orders/current/createWallet');
};
export const paymentCapabilities = () => __awaiter(void 0, void 0, void 0, function* () {
    return (yield httpClient.get('/orders/current/paymentCapabilities')).data;
});
export const initPaymentWithWire = () => {
    return httpClient.post('/orders/current/payment/wire');
};
export const cancelPaymentWithWire = () => {
    return httpClient.delete('/orders/current/payment/wire');
};
export const initPaymentWithApplePay = () => {
    return httpClient.post('/orders/current/payment/apple/init');
};
export const completePaymentWithApplePay = (paymentData) => {
    return httpClient.post('/orders/current/payment/apple', { payment: paymentData });
};
export const initPaymentWithSkrill = (billingAddress) => {
    return httpClient.post('/orders/current/payment/skrill', { billingAddress });
};
export const persistBillDetails = (billingAddress) => {
    return httpClient.post('/order/billingDetails', billingAddress);
};
