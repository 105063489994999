import { msg } from '@lingui/macro';
import { formatLongNumberToString } from '../../utils/formatNumber';
export class ExchangeInfoView {
    constructor(ttl, estimatedExchangeInfo, orderExchangeInfo, nftName) {
        this.ttl = ttl;
        this.estimatedExchangeInfo = estimatedExchangeInfo;
        this.orderExchangeInfo = orderExchangeInfo;
        this.nftName = nftName;
    }
    get exchangeInfo() {
        return this.orderExchangeInfo || this.estimatedExchangeInfo;
    }
    get isDefaultAmount() {
        return !('rate' in this.exchangeInfo);
    }
    get amounts() {
        return this.exchangeInfo.amounts;
    }
    get fee() {
        return 'fee' in this.exchangeInfo ? this.exchangeInfo.fee : null;
    }
    get paymentChannelFees() {
        if (this.orderExchangeInfo && 'fees' in this.orderExchangeInfo) {
            return this.orderExchangeInfo.fees;
        }
        else if ('fees' in this.estimatedExchangeInfo) {
            return this.estimatedExchangeInfo.fees;
        }
        else {
            return null;
        }
    }
    get rate() {
        return 'rate' in this.exchangeInfo ? this.exchangeInfo.rate : null;
    }
    get totalFeeString() {
        if (!this.fee) {
            return '';
        }
        return `${this.fee.total.inFiat.amount} ${this.fee.total.inFiat.currency}`;
    }
    get zeroFee() {
        if (!this.fee) {
            return null;
        }
        if (Number(this.fee.total.inFiat.amount) === 0) {
            return msg `Fees: ${this.totalFeeString}`;
        }
        else {
            return null;
        }
    }
    get networkFee() {
        if (!this.fee) {
            return '';
        }
        return `0.50 ${this.fee.total.inFiat.currency}`;
    }
    get atLeastFee() {
        if (!this.fee) {
            return '';
        }
        return `${(0.0099 * Math.floor(Number.parseFloat(this.amounts.from.amount))).toFixed(2)} ${this.fee.total.inFiat.currency}`;
    }
    get rateString() {
        if (!this.rate) {
            return '';
        }
        if (this.nftName) {
            return `${this.nftName} = ${formatLongNumberToString(this.rate)} ${this.amounts.from.currency.code}`;
        }
        return `1 ${this.amounts.to.currency.code} = ${formatLongNumberToString(this.rate)} ${this.amounts.from.currency.code}`;
    }
    get payAmountString() {
        return `${this.amounts.from.amount} ${this.amounts.from.currency.code}`;
    }
    get payRealAmountString() {
        return this.amounts.from.realCurrency && this.amounts.from.realAmount
            ? `${this.amounts.from.realAmount} ${this.amounts.from.realCurrency.code}`
            : null;
    }
    get receiveAmountString() {
        return `${formatLongNumberToString(+this.amounts.to.amount)} ${this.amounts.to.currency.code}`;
    }
}
