import { renderError, renderUI } from './View';
import { MessageTypes } from 'common';
import { createOrderFacade } from './createOrderFacade';
import { InitializationErrorsCode } from './Errors/InitializationErrors';
import { storage } from './Services/storage';
import './sentryInit';
import { languageAndTimeZone, networkInfo } from './analiticsUtils';
import { recording } from './openReplay';
const BootstrapWidget = (container, settings) => {
    var _a, _b;
    window.analytics.load(process.env.REACT_APP_CUSTOMER_IO_API_KEY);
    window.analytics.page();
    const bootstrapTimestamp = new Date().getTime();
    const initBootstrapDelta = (settings === null || settings === void 0 ? void 0 : settings.analyticsInfo)
        ? bootstrapTimestamp - (settings === null || settings === void 0 ? void 0 : settings.analyticsInfo.initTimestamp)
        : 'unknown';
    window.analytics.track('Widget Initialized', Object.assign(Object.assign(Object.assign({}, networkInfo()), languageAndTimeZone()), { initBootstrapDelta,
        bootstrapTimestamp, apiKey: settings === null || settings === void 0 ? void 0 : settings.apiKey, initTimestamp: (_a = settings === null || settings === void 0 ? void 0 : settings.analyticsInfo) === null || _a === void 0 ? void 0 : _a.initTimestamp, merchantUrl: (_b = settings === null || settings === void 0 ? void 0 : settings.analyticsInfo) === null || _b === void 0 ? void 0 : _b.merchantUrl }));
    window.addEventListener('focus', () => {
        window.analytics.track('user_focus_inside_widget_frame');
    });
    window.addEventListener('blur', () => {
        window.analytics.track('user_focus_outside_widget_frame');
    });
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            window.analytics.track('user_moved_to_other_tab');
        }
        else if (document.visibilityState === 'visible') {
            window.analytics.track('user_returned_to_widget_tab');
        }
    });
    if (settings.forceNewTransaction) {
        storage.clearAll();
    }
    if (settings.orderToken) {
        storage.clearAll();
        storage.TOKEN.set(settings.orderToken);
    }
    storage.API_KEY.set(settings.apiKey);
    recording
        .start()
        .then(() => createOrderFacade(settings))
        .then((result) => renderUI(container, settings, result))
        .catch((err) => {
        const errorCode = err.errorCode || InitializationErrorsCode.UnknowingError;
        renderError(container, settings, errorCode);
    })
        .finally(() => {
        container.classList.remove('loader');
    });
};
const rootEl = document.getElementById('root');
window.addEventListener('message', (event) => {
    const data = event.data || {};
    if (data.type === MessageTypes.START_APPLICATION && rootEl) {
        const settings = data.payload;
        BootstrapWidget(rootEl, settings);
    }
});
