import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useEffect } from 'react';
import { Button } from '../../Components/BaseComponents/Button';
import { Modal } from '../../Components/Modals/Modal';
import { useAnalytics } from '../../Contexts/AnalyticsContext';
import { SwipeluxLogo } from '../../Pages/OrderDetailsPage/icons';
export function OrderHistoryModal(props) {
    const { _ } = useLingui();
    const { track } = useAnalytics();
    useEffect(() => {
        track('order_history_modal_viewed');
    }, [track]);
    return (<Modal className="flex flex-col px-[var(--web-sides-padding)] pb-[var(--web-sides-padding)]" label={<SwipeluxLogo />} dataTestIdPrefix="history" {...props}>
      <div className="flex flex-col flex-1 items-center justify-center gap-6">
        <svg className="text-amber-500" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
          <rect x="2" y="6" width="20" height="8" rx="1"/>
          <path d="M17 14v7"/>
          <path d="M7 14v7"/>
          <path d="M17 3v3"/>
          <path d="M7 3v3"/>
          <path d="M10 14 2.3 6.3"/>
          <path d="m14 6 7.7 7.7"/>
          <path d="m8 6 8 8"/>
        </svg>

        <h2 className="text-2xl font-bold text-[--textBodyMain]">
          <Trans>Under Construction</Trans>
        </h2>

        <div className="flex flex-col gap-2">
          <p className="text-sm text-[--textBodyAdd] text-center text-pretty">
            <Trans>
              This feature is currently under development, check back soon.
            </Trans>
          </p>

          <p className="text-sm text-[--textBodyAdd] text-center text-pretty">
            <Trans>
              For now, please check your email for your order history.
            </Trans>
          </p>
        </div>
      </div>

      <div className="flex">
        <Button onClick={props.onClose} label={_(msg `Got it`)}/>
      </div>
    </Modal>);
}
